import React, { useEffect } from "react";
import Layout from "../components/layout";
import Head from "../components/head";
import { navigate } from "gatsby";
import "../styles/style.scss";

const IndexPage = () => {
  useEffect(() => {
    navigate('/events');
  }, []);

  return (
    <>
      <Head title="Mana Common Homepage" meta={
        [
          {
            name: 'google-site-verification',
            content: "JZZkenJxdIZu3CISpisqYyMTaG115mcBRWzH2l5xT_s",
          }
        ]
      } />
      <Layout>
        <div className="redirect-container">
          <div className="redirect-content">Redirecting to events page</div>
          <div className="animation-dots" />
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
